/*
TODO: [T1-8376] Move form state to global container At the page level we are processing query params in a useEffect and setting it in Global State. We then have a hook called multiFormStateSync that updates the form values by reading from global state. This update runs `reprocessForm` which causes a lot of issues because it runs all the updated values dynamic options request.
*/

/* eslint-disable react/prop-types */
import React, { useContext, useRef, useEffect } from 'react';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';
import ClickPortalResults from 'components/sections/lists/ClickPortalResults/ClickPortalResults';
import PageContainer from 'components/global/PageContainer';
import { pagePropsType } from 'utils/propTypes';
import Questionnaire from 'components/form-wizards/Questionnaire';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { CSSTransition } from 'react-transition-group';
import {
  getProgramTaxonomy,
  getQuestionBank,
  getQuestionnaires,
} from 'app-requests/triadmsRequests';
import { getDefaultPageSsrProps } from 'utils/getDefaultPageSsrProps';
import ClickPortalSearchHeader from 'components/form-wizards/click-portal-search/ClickPortalSearchHeader';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import ResponsiveImage from 'components/base/responsiveImage/ResponsiveImage';
import {
  getSchoolListingResultsFromQuery,
  handleImpressionKeyMapping,
} from 'app-requests/clickPortalSchoolManager';
import { TRIAD_USER_SESSION_ID } from 'utils/analyticsHelpers';
import { SITE_TYPE, defaultRedirect } from 'consts';
import handleServerRedirect from 'utils/handleServerRedirect';
import { handlerPersonalizedSearchRequest } from 'utils/generalUtils';

const TRANSITION_DURATION = 300;

export default function ClickPortalSearch(props) {
  const clickPortalSearchResultsRef = useRef(null);

  const { pageProps } = props;

  const {
    clickPortal: {
      results,
      title,
      subTitle,
      shouldShowSteps,
      impressionCacheKey,
      isLoading,
    },
    questionnaires,
    windowSize: { currentBreakpoint = 'mobile' },
    actions: { updateGlobalRefs, updateClickPortalData },
    sessionInfo: { pageViewId },
  } = useContext(GlobalContext);

  const { content, image } = questionnaires?.default?.sidebarContent ?? {};

  useEffect(() => {
    // TODO: [T1-10338] Use useMiroPortalFromHandler hook to load results from the micro portal forms. see startLeadSubmitPolling
    updateGlobalRefs({
      clickPortalSearchResultsRef,
    });
    return () => {
      updateGlobalRefs({
        clickPortalSearchResultsRef: null,
      });
    };
  }, []);

  useEffect(() => {
    if (pageViewId) {
      let schoolListingResults = { impressionCacheKey, results };

      /**
       * Its possible use navigated to this page via client side routing.
       * In the click portal form container we handle client side routing
       * from within the search page but on load of this page from another
       * page the pageProps come from the server and do not reset global context.
       */
      if (pageProps.schoolListingResults.results !== results) {
        schoolListingResults = pageProps.schoolListingResults;
        updateClickPortalData(pageProps.schoolListingResults);
      }

      handleImpressionKeyMapping(schoolListingResults).then(
        (updatedListingResults) => updateClickPortalData(updatedListingResults)
      );
    }
  }, [pageViewId]);

  return (
    <PageContainer pageProps={pageProps}>
      <div
        className={
          isLoading
            ? `clickPortalSearch clickPortalSearch--isLoading`
            : `clickPortalSearch`
        }
      >
        {currentBreakpoint === 'tablet' && (
          <div className="clickPortalSearch__header">
            <ClickPortalSearchHeader
              title={title}
              subTitle={subTitle}
              showTitle={currentBreakpoint === 'tablet' && !shouldShowSteps}
            />
          </div>
        )}
        <div className="clickPortalSearch__ctrls">
          {questionnaires.fullPage && (
            <Questionnaire
              {...questionnaires.default}
              showSteps
              disableTrustedForm
            />
          )}
        </div>
        <div className="clickPortalSearch__body">
          <CSSTransition
            in={shouldShowSteps}
            className="clickPortalSearch__progress"
            classNames="clickPortalSearch__progress"
            timeout={TRANSITION_DURATION}
          >
            <div>
              {image && (
                <div className="clickPortalSearch__progressImg">
                  <ResponsiveImage images={image} />
                </div>
              )}
              <div className="clickPortalSearch__progressContent">
                <Wysiwyg content={content} />
                <div
                  className="clickPortalSearch__progressSteps"
                  ref={clickPortalSearchResultsRef}
                />
              </div>
            </div>
          </CSSTransition>
          <CSSTransition
            in={!shouldShowSteps}
            appear
            unmountOnExit
            className="clickPortalSearch__content"
            classNames="clickPortalSearch__content"
            timeout={500}
          >
            <div>
              <div className="clickPortalSearch__header">
                <ClickPortalSearchHeader
                  title={title}
                  subTitle={subTitle}
                  showTitle={currentBreakpoint !== 'tablet' && !shouldShowSteps}
                />
              </div>
              <div className="clickPortalSearch__results">
                <DefaultErrorBoundary>
                  <ClickPortalResults />
                </DefaultErrorBoundary>
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
    </PageContainer>
  );
}

export async function getServerSideProps({ res, req, query }) {
  const domain = req.headers.host;

  let sessionId;

  const [siteSettings, questionBankMap, programTaxonomy] = await Promise.all([
    getDefaultPageSsrProps(domain, req.url, req.redisHelpers),
    getQuestionBank(req.redisHelpers).catch(() => null),
    getProgramTaxonomy(),
  ]);

  // TODO: [T1-10424] Create global routing mechanism at a per siteType basis
  if (
    ![SITE_TYPE.CLICK_PORTAL, SITE_TYPE.MICRO_PORTAL].includes(
      siteSettings?.meta?.siteType
    )
  ) {
    handleServerRedirect(defaultRedirect, { res, req });
    return {};
  }

  if (siteSettings?.meta?.siteType === 'microPortal') {
    sessionId = req.cookies[TRIAD_USER_SESSION_ID];
  }

  const { isPersonalized, geoLocation } = handlerPersonalizedSearchRequest({
    res,
    req,
  });

  const [questionnaires, schoolListingResults] = await Promise.all([
    getQuestionnaires({
      questionBankMap,
      domain,
      schoolCode: siteSettings.meta.schoolCode,
      formSettings: siteSettings.formSettings.questionnaires,
      redisHelpers: req.redisHelpers,
    }),
    getSchoolListingResultsFromQuery(
      query,
      programTaxonomy,
      /* meta */ {
        originalUrl: req.url,
        isPersonalized,
        geoLocation,
        sessionId,
        siteType: siteSettings?.meta?.siteType,
      }
    ),
  ]);

  return {
    props: {
      ...siteSettings,
      schoolListingResults,
      questionnaires: questionnaires || {},
      title: 'Find Your Program',
      header: {
        ...siteSettings.header,
        headerComponent: 'CLICK_PORTAL_HEADER',
      },
      meta: {
        ...siteSettings.meta,
        isPersonalized,
        geoLocation: {
          region: geoLocation?.cfRegion || '',
        },
        seoTitle: 'Search',
        siteName: 'HighestGrades',
      },
      // WP does not house this but Triad Backend Does
      microSiteTaxonomyMap: programTaxonomy,
    },
  };
}

ClickPortalSearch.propTypes = {
  pageProps: pagePropsType.isRequired,
};
